import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Table.module.scss'
import {
  FaCaretUp,
  FaCaretDown,
  FaChevronUp,
  FaChevronDown
} from 'react-icons/fa'
import ExternalLink from '../ExternalLink'
import SymbolLink from '../SymbolLink'
import Tooltip from '../Tooltip'
import Hide from '../Hide'
import { format } from '../../utils/locale'

function formatCell (column, value) {
  switch (column.type) {
    case 'tick':
      if (value === 'Up') return <FaCaretUp className={styles.tick} />
      else if (value === 'Down') return <FaCaretDown className={styles.tick} />
      else return null
    default:
      return format(value, '', column.type)
  }
}

function getAlign (column) {
  switch (column.type) {
    case 'price':
    case 'percentage':
    case 'tenths':
    case 'millions':
    case 'int':
    case 'seconds':
    case 'float':
    case 'sign':
      return 'right'
    default:
      return 'left'
  }
}

const isEmptyValue = (value) => value === null || value === undefined || (typeof value === 'string' && value.trim() === '')

class Table extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      sortColumn: this.props.sortColumn,
      sortDirection: this.props.sortDirection,
      expandedRows: {}
    }
  }

  onSorting = (column) => {
    return () => {
      let sortDirection = 'unset'

      if (this.state.sortDirection === 'asc') {
        sortDirection = 'desc'
      } else {
        sortDirection = 'asc'
      }

      this.setState(state => ({
        sortDirection,
        sortColumn: column.name,
        expandedRows: {}
      }), () => {
        if (this.props.onSorting) {
          this.props.onSorting(column.sortingKey || column.name, sortDirection)
        }
      })
    }
  }

  expandRow = (i) => {
    const expanded = this.state.expandedRows[i] !== undefined ? !this.state.expandedRows[i] : true
    const expandedRows = { ...this.state.expandedRows, [i]: expanded }
    this.setState({ expandedRows })
  }

  getHeader = (column) => {
    const data = this.props.data
    return column.header(data && data.records ? data.records[0] : data[0])
  }

  render () {
    let data = this.props.data
    const accentColor = this.props.accent
    if (data && data.records) data = data.records

    if (this.props.max) data = data.slice(0, this.props.max)

    return <div className={styles.tableContainer}><table className={classNames({
      [styles.table]: true,
      [styles.overline]: this.props.overline,
      [styles.thickOverline]: this.props.thickOverline,
      [styles.largeFont]: this.props.largeFont
    }, this.props.className)}>
      {this.props.showHeader
        ? <thead>
        <tr>
          {this.props.numbered ? <th className={styles.cell} /> : null}
          {this.props.columns && this.props.columns.map(column => (
            <th
              key={column.name}
              onClick={column.sortingEnabled ? this.onSorting(column) : undefined}
              className={classNames({
                [styles.cell]: true,
                [styles[column.align || getAlign(column)]]: true,
                [styles.hideOnMobile]: column.hideOnMobile,
                [styles.hideOnTablet]: column.hideOnTablet,
                [styles.clickable]: column.sortingEnabled
              })}>

              <Hide inline sm>{typeof column.header === 'function' ? this.getHeader(column) : column.header || column.name}</Hide>
              <Hide inline md lg>{column.headerShort || column.header || column.name}</Hide>
              {((this.state.sortColumn === column.name ||
                (this.state.sortColumn === column.sortingKey && column.sortingKey)) &&
                this.state.sortDirection === 'desc') && <span
                  className={styles.sortDirection}>
                  <FaCaretDown />
                </span>}

              {((this.state.sortColumn === column.name ||
                (this.state.sortColumn === column.sortingKey && column.sortingKey)) &&
                this.state.sortDirection === 'asc') && <span
                  className={styles.sortDirection}>
                  <FaCaretUp />
                </span>}
            </th>
          ))}
          {this.props.expandedSection && <th className={styles.cell} />}
        </tr>
      </thead>
        : null}
      <tbody>
        {data && data.map((item, i) => {
          if (!item) return null
          const ExtendedRow = item.extendedRow
          return [<tr key={item._id || item.id || i}>
            {item.extendedRow && <td
              width='1'
              colSpan={this.props.columns.length + (this.props.numbered ? 1 : 0)}
              className={classNames([styles.cell, styles.extendedCell])}>
              <ExtendedRow data={item} />
            </td>}
            {(!item.extendedRow && this.props.numbered)
              ? <td width='1' className={styles.cell}>
                <span className={styles.numberIcon}>{i + 1}</span>
              </td>
              : null}
            {!item.extendedRow && this.props.columns.map(column => {
              let strLinkTo = null
              let ColumnComponent = column.component
              const symbolLinkOptions = {}

              const value = item[column.name]
              const formattedValue = isEmptyValue(value) ? column.emptyValue : formatCell(column, value)
              let tooltip = null
              if (column.tooltip) {
                tooltip = typeof column.tooltip === 'function' ? column.tooltip(item) : column.tooltip
              }

              if (column.type === 'symbol') {
                ColumnComponent = SymbolLink

                // setup custom link parameter names
                if (column.componentOptions) {
                  if (column.componentOptions.symbol) {
                    symbolLinkOptions.symbol = item[column.componentOptions.symbol]
                  }

                  if (column.componentOptions.tierId) {
                    symbolLinkOptions.tier = item[column.componentOptions.tierId]
                  }

                  if (!symbolLinkOptions.tier && column.componentOptions.tierCode) {
                    symbolLinkOptions.tier = item[column.componentOptions.tierCode]
                  }

                  if (column.componentOptions.disableCaveatEmptor) {
                    symbolLinkOptions.disableCaveatEmptor = true
                  }
                }
              }

              if (column.linkTo) {
                strLinkTo = typeof column.linkTo === 'string' ? column.linkTo : column.linkTo(item)
              }

              if (column.type === 'url' && formattedValue) {
                strLinkTo = formattedValue
              }

              const CellContent = <span>
                {!ColumnComponent && <span className={column.name === 'sharesTraded' && item.stockTransaction
                  ? styles[item.stockTransaction] : column.bold && styles.bold}>
                  {
                    (strLinkTo)
                      // If cell is a link
                      ? <ExternalLink
                        className={classNames(styles.link, styles[accentColor])}
                        external={column.type === 'url' || column.target === 'blank'}
                        ga={this.props.ga}
                        to={strLinkTo}>{column.type !== 'url' && formattedValue}</ExternalLink>
                      // Standard cell
                      : formattedValue
                  }
                </span>}
                {ColumnComponent && <ColumnComponent
                  options={column.componentOptions}
                  data={item}
                  value={value}
                  ga={this.props.ga}
                  {...symbolLinkOptions}
                />}
              </span>

              return <td key={column.name}
                style={{ color: typeof column.color === 'function' ? column.color(item) : column.color }}
                className={classNames(styles.cell, {
                  [styles.noWrap]: column.noWrap,
                  [styles[column.align || getAlign(column)]]: true,
                  [styles.hideOnMobile]: column.hideOnMobile,
                  [styles.hideOnTablet]: column.hideOnTablet
                })}>
                {tooltip
                  ? <Tooltip {...tooltip}>
                  {CellContent}
                </Tooltip>
                  : CellContent}
              </td>
            })}
            {this.props.expandedSection && <td className={styles.cell}>
              {this.props.expandedSection && this.props.expandedSection(item) &&
                <span className={styles.expandChevron} onClick={() => this.expandRow(i)}>
                  {this.state.expandedRows[i] ? <FaChevronUp /> : <FaChevronDown />}
                </span>
              }
            </td>}
          </tr>, this.props.expandedSection && this.state.expandedRows[i] && <tr key={(item._id || item.id || i) + '_expanded'}>
            <td colSpan={this.props.columns.length + 1} className={styles.cell}>
              {this.props.expandedSection(item)}
            </td>
          </tr>]
        })}
      </tbody>
    </table></div>
  }
}

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    /** column key in source data */
    name: PropTypes.string.isRequired,
    /** column display name for headers */
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /** column display name for headers in mobile */
    headerShort: PropTypes.string,
    /** column alignment: "left", "center", "right" */
    align: PropTypes.oneOf(['left', 'center', 'right']),
    /** hide column in mobile displays? */
    hideOnMobile: PropTypes.bool,
    /** hide column in tablet displays? */
    hideOnTablet: PropTypes.bool,
    /** bold values */
    bold: PropTypes.bool,
    /** sorting enabled */
    sortingEnabled: PropTypes.bool,
    /** sorting key (column.name will be used if unavailable) */
    sortingKey: PropTypes.string,
    /** custom column component */
    component: PropTypes.func,
    /** component option values */
    componentOptions: PropTypes.object,
    /** text to display if cell value is null or undefined */
    emptyValue: PropTypes.string,
    /** data type, for display formatting */
    type: PropTypes.string,
    /**
     * simple string or function that generates link string from item data,
     * i.e. `(item) => '/test/' + item._id`
     */
    linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /**
     * simple string or function that generates color string from item data,
     * i.e. `(item) => item.field < 0 && 'red'`
     */
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /**
     * object or function that generates tooltip from item data,
     * i.e. `(item) => ({ text: item.field })`
     */
    tooltip: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
  })).isRequired,
  /** items to render */
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.shape({
      records: PropTypes.arrayOf(PropTypes.object)
    })
  ]),
  /** show column headers (by column.header or column.name if unavailable) */
  showHeader: PropTypes.bool,
  /** show number icons per item */
  numbered: PropTypes.bool,
  /** function that generates expanded content from item data */
  expandedSection: PropTypes.func,
  /** show green top border */
  overline: PropTypes.bool,
  /** thick green top border */
  thickOverline: PropTypes.bool,
  /** full-size font */
  largeFont: PropTypes.bool,
  /** current sorted column */
  sortColumn: PropTypes.string,
  /** sorting direction */
  sortDirection: PropTypes.oneOf(['asc', 'unset', 'desc']),
  /** sort callback */
  onSorting: PropTypes.func,
  /** custom className */
  className: PropTypes.string,
  /** max amount of data items to shouw */
  max: PropTypes.number,
  /** Custom color class name to be used on link hovers */
  accent: PropTypes.string,
  /** ga object for tracking events */
  ga: PropTypes.object
}

export default Table
